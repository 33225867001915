<script lang="ts" setup>
import AppStore from '~/components/reusables/AppStore.vue'
import GooglePlay from '~/components/reusables/GooglePlay.vue'
import LaunchWebApp from '~/components/reusables/LaunchWebApp.vue'

const {
  public: { siteUrl },
} = useRuntimeConfig()

const { t } = useI18n()

useSeoMeta({
  title: t('belongs-mobile-app-for'),
  description: t('download-and-install-belong-application'),
  ogTitle: t('belongs-mobile-app-for'),
  ogDescription: t('download-and-install-belong-application'),
  ogImage: `${siteUrl}/images/head/snippet_screen.png`,
  ogImageWidth: '1200',
  ogImageHeight: '630',
  ogImageType: 'image/png',
  ogType: 'website',
  twitterTitle: t('belongs-mobile-app-for'),
  twitterDescription: t('download-and-install-belong-application'),
  twitterImage: `${siteUrl}/images/head/snippet_screen.png`,
  twitterCard: 'summary_large_image',
})

definePageMeta({
  layout: 'menu',
})

const turnPassesList = [
  {
    text: t('verification-of-authenticity'),
  },
  {
    text: t('proof-of-ownership'),
  },
  {
    text: t('liquidity'),
  },
]
</script>

<template>
  <div>
    <section class="relative">
      <div
        class="flex justify-center min-h-175 relative z-2 flex-col max-h-max lg:flex-row lg:max-h-175"
      >
        <div class="flex flex-col items-center mt-13.5 lg:font-500 lg:mt-49">
          <h1
            class="font-500 tracking-wide mt-9.5 mb-17.5 text-white px-3.5 max-w-80.5 text-2xl text-center sm:text-3xl sm:max-w-full"
          >
            {{ t('belong-mobile-application') }}
          </h1>
          <div
            class="flex flex-col items-center justify-center gap-4 sm:flex-row"
          >
            <AppStore />
            <GooglePlay />
            <LaunchWebApp />
          </div>
        </div>
      </div>
      <div class="opacity-50 absolute top-0 z-1 w-full h-175">
        <video
          class="w-full h-175 object-cover absolute top-0 -z-1"
          autoplay
          loop
          muted
          playsinline
        >
          <source src="/images/app/video.mp4" type="video/mp4" />
        </video>
      </div>
    </section>
    <section>
      <div
        class="flex flex-col items-center justify-center pt-28 pb-9.75 sm:pb-22"
      >
        <NuxtImg
          loading="lazy"
          alt="Find Belong Network on Product Hunt"
          class="w-45.75 h-full object-cover"
          src="/images/app/product-hunt.svg"
        />
        <div
          class="font-600 text-white mt-4 px-7 text-center text-[22px] sm:text-[28px]"
        >
          {{ t('find-belong-network') }}
        </div>
      </div>
      <div class="w-full h-0.25 bg-[#2c2c2c]"></div>
    </section>
    <section v-if="false">
      <SliderCollection />
    </section>
    <section>
      <div
        class="flex flex-col items-center mx-auto pt-8.5 pb-15.5 md:flex-row-reverse md:justify-center md:items-start lg:max-w-245 lg:justify-between"
      >
        <div class="flex justify-center w-full relative md:w-92.75">
          <NuxtImg
            loading="lazy"
            alt="Log in with NFT"
            src="/images/app/phone-nft.webp"
            class="w-75.5 h-127.25 object-cover !m-0 hidden md:flex"
          />
          <NuxtImg
            loading="lazy"
            alt="Log in with NFT"
            class="w-65.75 h-54.75 object-cover z-1 static md:hidden"
            src="/images/app/phone-nft-alert.webp"
          />
          <NuxtImg
            v-motion
            :initial="{
              x: -200,
            }"
            :visible="{
              x: 0,
              transition: {
                duration: 5000,
              },
            }"
            loading="lazy"
            alt="Log in with NFT"
            class="absolute top-26.75 right-0 hidden lg:flex"
            src="/images/app/phone-nft-alert.webp"
          />
        </div>
        <div class="mr-0 max-w-115 mt-6 md:mt-36.5">
          <h2
            class="font-400 mb-0 text-white text-[28px] text-center lg:text-4xl lg:text-left"
          >
            <span class="text-[#B696FF]">{{ t('log-in') }}</span>
            {{ t('with-nft') }}
          </h2>
          <p
            class="font-500 text-lg tracking-wide text-[#979797] mt-6 text-center mx-4 lg:mt-10 lg:mx-0 lg:text-left"
          >
            {{ t('log-in-with-your') }}
          </p>
        </div>
      </div>
      <div class="w-full h-0.25 bg-[#2c2c2c]" />
    </section>
    <section>
      <NotificationsThatMatter />
      <div class="w-full h-0.25 bg-[#2c2c2c]" />
    </section>
    <section>
      <div
        class="flex flex-col pb-16 pt-7 lg:flex-row-reverse lg:justify-between lg:max-w-244.5 lg:pb-12 lg:pt-10.5 lg:mx-auto"
      >
        <div class="connect-holders relative hidden lg:flex lg:justify-center">
          <NuxtImg
            loading="lazy"
            alt="Connect with Holders"
            src="/images/app/phone-connect.webp"
            class="w-119.5 h-123.5 object-cover"
          />

          <NuxtImg
            loading="lazy"
            alt="Connect with Holders"
            class="animation-fade w-77.5 absolute top-37 z-1 left-auto right-auto lg:-right-10"
            src="/images/app/phone-connect-alert.webp"
          />
        </div>
        <div class="connect-holders-mobile">
          <NuxtImg
            loading="lazy"
            alt="Connect with Holders"
            class="w-63 h-full"
            src="/images/app/phone-7-mobile.webp"
          />
          <NuxtImg
            loading="lazy"
            class="w-77.5 h-24.25 absolute z-2"
            alt="Connect with Holders"
            src="/images/app/phone-7-mobile-alert.webp"
          />
        </div>
        <div class="text-center mx-11.5 lg:mx-0 lg:max-w-115 lg:mt-34 lg:text-left">
          <h2 class="font-400 text-[28px] text-white sm:text-4xl">
            <span class="text-[#FFCA5A]">{{ t('connect') }} </span>
            {{ t('with-holders') }}
          </h2>
          <p
            class="font-500 text-lg mt-6 tracking-wide text-[#979797] md:mt-7.5"
          >
            {{ t('connect-with-other-holders') }}
          </p>
        </div>
      </div>
      <div class="w-full h-0.25 bg-[#2c2c2c]" />
    </section>
    <section>
      <div
        class="flex flex-col pt-7 pb-17.5 xl:max-w-291 xl:flex-row xl:pb-0 xl:pt-40 xl:justify-between xl:mx-auto"
      >
        <div class="flex flex-col items-center">
          <div class="mx-10.5 h-55.75 xl:mx-0">
            <client-only>
              <Lottie src="/lottie/phone-meet.json" loop autoplay />
            </client-only>
          </div>
          <NuxtImg
            loading="lazy"
            alt="Meet IRL"
            src="/images/app/phone-meet-2.webp"
            class="hidden md:w-157 md:flex"
          />
        </div>
        <div
          class="mt-9 text-center mx-6.5 lg:mx-auto lg:max-w-113 xl:mt-4.5 xl:text-left"
        >
          <h2 class="font-400 text-4xl text-white">
            <span class="text-[#FFB0F0]">{{ t('meet') }} </span>{{ t('irl') }}
          </h2>
          <p class="font-500 text-lg mt-10 tracking-wide text-[#979797] mt-4)">
            {{ t('coordinate-irl-encounters') }}
          </p>
        </div>
      </div>
      <div class="w-full h-0.25 bg-[#2c2c2c]" />
    </section>

    <section class="turn-passes">
      <div
        class="flex flex-col pt-9.5 pb-11 lg:flex-row lg:max-w-245.5 lg:justify-between lg:mx-auto"
      >
        <div
          class="text-white px-5 text-center flex flex-col items-center lg:px-0 lg:mt-25 lg:items-start lg:text-left"
        >
          <div class="max-w-127.5 text-2xl tracking-wide leading-[36px]">
            {{ t('belong-turns-passes-membership') }}
          </div>
          <div
            class="flex flex-col items-center text-[#E0E0E0] text-lg tracking-wide leading-[34px] mt-4.5 lg:items-start"
          >
            <div
              class="flex flex-row items-center"
              v-for="item in turnPassesList"
            >
              <NuxtImg
                loading="lazy"
                :alt="item.text"
                src="/images/app/list-icon.svg"
                class="w-3.5 h-2.25"
              />
              <div class="ml-2.5">{{ item.text }}</div>
            </div>
          </div>
          <div class="text-[#B5B5B5] text-base tracking-wide mt-6.5">
            {{ t('all-in-a-safe') }}
          </div>
          <NuxtLink
            to="/"
            class="flex items-center justify-center no-underline rounded-full bg-black text-white border-solid border-[#515151] h-11 w-60.5 font-500 text-[13px] tracking-wider mt-9"
          >
            <NuxtImg
              loading="lazy"
              alt="Mint"
              src="/images/app/mint-your.svg"
              class="w-7.25 h-6.75 mr-2.5"
            />
            {{ t('mint-your-community-nft') }}
          </NuxtLink>
        </div>
        <NuxtImg
          loading="lazy"
          class="w-73.5 z-1 hidden lg:flex"
          alt="Turn passes"
          src="/images/app/turn-passes.webp"
        />
      </div>
      <div class="w-full h-0.25 bg-[#2c2c2c]" />
    </section>
    <section>
      <div
        class="founder flex flex-col items-center max-w-240.5 pb-5.5 pt-10 lg:flex-row lg:mx-auto lg:items-start lg:justify-between lg:py-6"
      >
        <div class="web3 relative">
          <NuxtImg
            loading="lazy"
            alt="Phone"
            class="w-73.25 h-81 object-cover object-top lg:w-80.75 lg:h-137.25"
            src="/images/nft/phone-5.webp"
          />
        </div>
        <div class="mt-6 px-11.5 lg:max-w-115 lg:mt-38.5 lg:px-0">
          <div
            class="text-[28px] leading-[42px] tracking-wide text-white text-center lg:text-left"
          >
            {{ t('your') }} <span class="text-[#ff9595]">{{ t('web3') }}</span>
            {{ t('homepage') }}
          </div>
          <div
            class="max-w-115 mt-5 text-center text-[18px] leading-[27px] tracking-wide text-[#CECECE] lg:mt-8.5 lg:text-left"
          >
            {{ t('get-a-short') }}
            <span class="text-[#57A5FF]">{{ t('belong-net-yourname') }}</span>
            {{ t('profile-with-your-nft') }}
          </div>
        </div>
      </div>
      <div class="w-full h-0.25 bg-[#272727]" />
    </section>
    <section>
      <EnrichedMemberProfiles />
      <div class="w-full h-0.25 bg-[#272727]" />
    </section>

    <section>
      <div
        class="exclusive-access relative flex flex-col items-center bg-[#222222] pt-34"
      >
        <div class="flex flex-col items-center relative z-2 px-5">
          <h2 class="font-400 text-[28px] text-white text-center md:text-4xl">
            {{ t('use-your') }}
            <span class="text-[#FFCA5A]">{{ t('exclusive-access') }} </span>
          </h2>
          <p
            class="text-lg mt-3 max-w-88.5 tracking-wide text-[#979797] text-center sm:max-w-115"
          >
            {{ t('get-access-to-private') }}
          </p>
        </div>
        <div class="w-59.25 h-53.75 absolute top-6.5 z-1">
          <div class="crypto-access relative">
            <NuxtImg
              loading="lazy"
              alt="Crypto address"
              class="w-59.25 h-53.75 object-cover"
              src="/images/app/crypto-access.webp"
            />
          </div>
        </div>
      </div>
    </section>
    <section>
      <Supported class="pt-8 xl:pt-32.5 pb-17" />
    </section>
    <section>
      <div
        class="flex flex-col justify-center mt-11.5 pb-12.5 lg:max-w-275 lg:flex-row-reverse lg:justify-between lg:mx-auto lg:pt-6.5 lg:mt-13.5 lg:px-9 lg:pb-6.5 xl:px-0"
      >
        <div class="w-52.5 mx-auto lg:w-96.5 lg:mx-0">
          <client-only>
            <Lottie src="/lottie/collab.json" loop autoplay />
          </client-only>
        </div>
        <div
          class="px-9 mt-8.5 text-center lg:mt-23 lg:text-left lg:max-w-156 lg:px-0"
        >
          <div
            class="text-[#E0BDFF] text-4xl font-500 tracking-wide leading-normal break-words"
          >
            {{ t('have-a-collection-want-to-collab') }}
          </div>
          <div
            class="text-[#B5B5B5] text-[1.375rem] mb-12 mt-5 font-500 max-w-158 leading-8 tracking-wide leading-normal mx-auto"
          >
            {{ t('reach-out-so-we') }}
          </div>
          <div
            class="flex flex-row justify-center items-center lg:justify-start"
          >
            <NuxtImg
              loading="lazy"
              alt="join@belong.net"
              src="/images/app/email.svg"
              class="w-6 h-full object-cover"
            />
            <NuxtLink
              class="text-[#FFCA5A] no-underline text-lg font-500 ml-2.5"
              to="mailto:join@belong.net"
              target="_blank"
              >join@belong.net</NuxtLink
            >
          </div>
        </div>
      </div>
    </section>
    <section>
      <InstallSection class="px-5" />
    </section>
  </div>
</template>

<style scoped>
.animation-fade {
  animation: 2s fade-2 ease-in-out both;
}

@keyframes fade {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes fade-2 {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translateX(-100px);
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes translateX {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translateX(-50px);
  }
}

.crypto-access {
  &::before {
    @apply bottom-0 h-33.75 absolute w-full content-[''];
    background: linear-gradient(0, #222222 0%, rgba(34, 34, 34, 0) 100%);
  }
}

.swiper-wrapper {
  transition-timing-function: ease-out;
}

.turn-passes {
  --at-apply: bg-none;
  &:before {
    --at-apply: hidden;
  }
}

.founder {
  --at-apply: relative;
  &:after {
    @apply: bottom-0 h-42 absolute w-full content-[''];

    background: linear-gradient(180deg, rgba(21, 21, 21, 0) 0%, #151515 68.05%);
  }
}

.web3 {
  &::after {
    @apply: bottom-0 h-18 absolute w-full content-[''] left-0 right-0 z-2;

    background: linear-gradient(0deg, #151515 0%, rgba(34, 34, 34, 0) 100%);
  }
}

.connect-holders-mobile {
  @apply flex items-center justify-center relative w-full lg-hidden;
  &::after {
    @apply: absolute w-full h-63 bottom-0 content-[''];
    background: linear-gradient(0, #151515 0%, rgba(34, 34, 34, 0) 100%);
  }
}

@media (max-width: 1279.9px) {
  .community,
  .founder {
    background: none;
    &:before,
    &:after {
      display: none;
    }
  }
}

@media (min-width: 1024px) {
  .turn-passes {
    @apply relative bg-cover bg-center;
    background: url(~/assets/images/app/turn-passes-bg.webp);
    &:before {
      @apply flex absolute top-0 w-full h-42.5 content-[''];
      background: linear-gradient(180deg, #151515 0%, rgba(21, 21, 21, 0) 100%);
    }
  }
}

@media (max-width: 767.9px) {
  .animation-fade {
    animation: none;
  }

  .hubs-events {
    &::before,
    &::after {
      --at-apply: hidden;
      display: none;
    }
  }

  .connect-holders {
    --at-apply: relative;

    &::before {
      @apply: absolute -bottom-2 w-full h-full z-1 content-[''];

      background: linear-gradient(270deg, #151515 0%, rgba(34, 34, 34, 0) 100%);
      transform: rotate(90deg);
    }
  }
}

.exclusive-access {
  @apply: apply relative content-[''];
  &:before {
    @apply absolute w-full h-34.25 bottom-0;
    background: linear-gradient(0, #151515 0%, rgba(34, 34, 34, 0) 100%);
  }
}
</style>
